var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 5000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

var subsiteHijackMainNav = [
  'lifesaving-sport-area',
  'water-safety-advice-for-your-business',
  'rlss-uk-membership-area',
  'water-safety-advice-for-you',
  'my-rlss-uk'
];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var sidebarCards = true; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// Format listing page carousels
if ($('.subsite:not(.homepage)').length) {
  $('.headerWrapperSubsite').remove();
  $('.subsiteBody').remove();
}

// Carousel wrapper for background
$('.carousel').wrap('<div class="carouselWrapper">');

// Extra event feed button
if ($('body').hasClass('homepage') && !$('body').hasClass('subsite')) {
  $('<a class="readMore allEvents" href="/events">View all events</span>').insertAfter('.homeFeedBox1 .readMore');
}

// Add newsletter class to first footer box to adopt Fresco layout
$('.footerBox1').addClass('NewsletterSign-up');

// Use footer snippet img as background image
$('.footerBox1').css('background-image', 'url("' + $('.footerBox1 img').first().attr('src') + '")');

// Wrap all but 1st footer box
$('.footerBox:not(.footerBox1)').wrapAll('<div class="footerBoxWrapper">');

if (!$('.carouselSlides:not(#carouselSlidesSubsite) .carouselSlideTitle').length && $('section.listing').length) {
  $('li.carouselSlide img').wrap('<a class="carouselSlideTitle">');
  $('h1.title').wrap('<div class="carouselDetailWrapper"><div class="carouselSlideDetail"></div></div>').addClass('carouselSlideHeading');
  $('.carouselDetailWrapper').insertAfter('.carouselSlideTitle');
}

$('.breadcrumbWrapper').insertBefore('.carouselSlideHeading');

// Post carousel background element
if ($('body[class*="PostBody"]').length) {
  $('.carouselWrapper').prepend('<div class="postCarouselBackground">');
}

// Blockquote attribution support
// Thing to make blockquote attributes easier
if (($('body[class*="PostBody"]').length = 1)) {
  var blockquotes = document.querySelectorAll("blockquote");
  for (var i = 0; i < blockquotes.length; i++) {
    if (blockquotes[i].innerHTML.includes("quote-attribute")) {
      blockquotes[i].classList.add("contains-attribution");
    }
  }
}

// Sidebar featured posts
if ($('.associatedBox').length) {
  $('.associatedBox').insertBefore('.associatedLatestPosts');
  $('.associatedBox').wrap('<div class="associatedBoxWrapper">');
  $('.associatedBoxWrapper').prepend('<div class="associatedBoxBackground">');
}

// Function for 'in-view' class assignment
function inView(element) {
  const $element = $(element);
  $(window).on("load resize scroll", function () {
    if ($element.isInViewport()) {
      $element.addClass("in-view");
    }
  });
}

$(".homeIntro > *").each(function () {
  inView(this);
});

$('document').ready(function(){
  setTimeout(() => {
    // Search
    $('.pageHeader .searchContainer').click(function(){
      window.location.href = "/search";
    })    
  }, 100);
})

// Subsite carousel organisation
if ($('body').hasClass('subsite')) {
  let headerText = $('#h1SubsiteTitle').text();
  $(`<div class="carouselDetailWrapper"><div class="carouselSlideDetail"><h2 class="carouselSlideHeading"><a>${headerText}</a></h2></div></div>`).insertAfter('a.carouselSlideTitle');
}

if ($('.carouselWrapper .headerText > div:first-child').text() == "\n") {
  $('.carouselWrapper .headerText').remove();
  $('.contentBlockWrapper').css('margin-top', '2rem');
}

// Footer logos
$(".footerBox.Footer-Logos").insertAfter(".pageFooterWrapper");

var copy = document.querySelector(".logos-slide").cloneNode(true);
document.querySelector(".logos").appendChild(copy);